import Head from "next/head";
import { LanguageProvider } from "../utils/language";
import { AppProvider } from "../context/AppContext";
import "../../src/style.scss";
import { AnimatePresence } from "framer-motion";
import { AppProps } from "next/app";
import { usePreserveScroll } from "../utils/usePreserveScroll";
import { SearchProvider } from "../context/SearchContext";
import { useEffect, useRef, useState } from "react";
import { googleoffNextData } from "../utils/googleoff";
import { useScriptInjection } from "@aleksundshantu.com/aunds-cookie-plugin-react";
import ReactLenis from "lenis/dist/lenis-react";
import { useRouter } from "next/router";

function MyApp({ Component, pageProps }: AppProps) {
  // usePreserveScroll();
  useScriptInjection(process.env.WP_API_URL!);

  useEffect(() => {
    googleoffNextData();
  }, []);

  const router = useRouter();
  const browserNavigation = useRef(false); //e.g. browser back button was used
  const [pageTransition, setPageTransition] = useState(false);

  useEffect(() => {
    router?.beforePopState(() => {
      browserNavigation.current = true;
      return true;
    });
    const handleRouteChangeStart = () => {
      setPageTransition(true);
    };
    const handleRouteChange = (
      url: string,
      { shallow }: { shallow: boolean }
    ) => {
      if (!shallow && !browserNavigation.current) {
        document.getElementById("pagestart")?.focus();
        document.getElementById("pagestart")?.blur();
      }
      browserNavigation.current = false;
      setPageTransition(false);

      router.events.on("routeChangeStart", handleRouteChangeStart);

      router.events.on("routeChangeComplete", handleRouteChange);
      return () => {
        router.events.off("routeChangeComplete", handleRouteChange);
        router.events.off("routeChangeStart", handleRouteChangeStart);
      };
    };
  }, [router, router.events]);
  return (
    <>
      <LanguageProvider>
        <Head>
          {/* you can add metadata here, for all pages */}
          <meta charSet="utf-8" />
          <meta name="viewport" content="width=device-width, initial-scale=1" />
        </Head>

        <AnimatePresence>
          <AppProvider>
            <SearchProvider>
              <ReactLenis
                options={{
                  lerp: pageTransition ? 0 : 0.05,
                }}
                className="wrapper"
                root
              >
                <Component {...pageProps} />
              </ReactLenis>
            </SearchProvider>
          </AppProvider>
        </AnimatePresence>
      </LanguageProvider>
    </>
  );
}

export default MyApp;
