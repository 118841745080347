import { getUrl } from "../utils/url";
import { AzFilterPostType, WikiType } from "../utils/baseTypes";
import { fetchWithRetry } from "../utils/fetchWithRetry";

export async function getWikiPerLetter(
  letter: string | null,
  postsPerPage?: number,
  page?: number,
  lang?: string,
  defaultLang?: string
): Promise<AzFilterPostType> {
  const dynamicParams = dynamicParamUrl(postsPerPage, page);
  const letterParam = letter ? `letter=${letter}` : "";
  const url = `${getUrl(
    lang,
    defaultLang
  )}/aunds/wiki?${letterParam}${dynamicParams}`;

  const wikisRes = await fetchWithRetry(url);
  return (await wikisRes.json()) as Promise<AzFilterPostType>;
}

// FIXME: add some input validation here. The backend will happily return even
// all 1000+ posts in full, killing the server in the process.
// `per_page` capped to the global max allowed results for this endpoint. That
// max has to be implemented in the backend. 24 is what we have now in the user
// option `data.az_grid.posts_per_page`. Sounds reasonable as max value. The
// endpoint `encyclopedia` currently needs 50, as that is fetched to create the
// tooltips.
// `page` could be validated server-side before generating the REST response,
// return simple not found REST response if too big.
export function dynamicParamUrl(postsPerPage?: number, page?: number) {
  // FIXME: validation could be done here too to avoid sending anything to the
  // backend in case of wrong params (valid int greater than 0, not bigger than
  // the global max). But TS doesn't check at runtime. Some generic, global
  // validation is needed, so I'm not just modifying this one function. Instead,
  // here, have a log
  !(postsPerPage && page) &&
    console.error(
      `WARN: dynamicParamUrl: faulty param(s):\n  postsPerPage: ${postsPerPage}\n  page: ${page}`
    );
  return postsPerPage && page
    ? "&per_page=" + postsPerPage + "&page=" + page
    : "";
}

interface AllWikiReturnType {
  allWikis: WikiType[];
  totalPosts: number;
}

export async function getAllWikis(
  taxonomy?: string,
  taxonomyID?: string,
  taxRelation?: "AND" | "OR",
  postsPerPage?: number,
  page?: number,
  lang?: string,
  defaultLang?: string
): Promise<AllWikiReturnType> {
  const dynamicParams = dynamicParamUrl(postsPerPage, page);
  const filterTaxonomies =
    taxonomy && taxonomyID ? `${taxonomy}` + "=" + `${taxonomyID}` : "";

  const url = `${getUrl(
    lang,
    defaultLang
  )}/aunds/encyclopedia?${filterTaxonomies}${dynamicParams}`;

  const pageRes = await fetch(url);

  const totalPosts = Number(pageRes.headers.get("X-WP-Total"));

  const allWikis = await pageRes.json();

  return { allWikis, totalPosts };
}
